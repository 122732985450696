import { get } from "lodash";
import { getContactFormErrors } from "../components/Utils";

let defaultState = {
	fetching: false,
	status: false,
	serverError: [],
	data: {}
}

export default function submitReducer(state = {
	newslettersubscribe: defaultState,
	contactform: defaultState,
}, action) {
	switch (action.type) {
		case "SUBMIT_PENDING": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: true,
					status: false,
				},
			}
		}
		case "SUBMIT_REJECTED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					status: get(action.payload, "response.status", 400),
				},
			}
		}
		case "SUBMIT_FULFILLED": {
			const { attribute } = action.meta;
			let status = get(action.payload, "data.status", "mail_sent");
			let serverError = (status === "validation_failed") ? getContactFormErrors(get(action.payload, "data.invalid_fields", [])) : [];
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					status: status === 'mail_sent' ? 201 : 400,
					data: action.payload.data,
					serverError: serverError,
				},
			}
		}
		case "SUBMIT_RESET": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					status: false,
					serverError: [],
				},
			}
		}
		default: {
			return state;
		}
	}
}