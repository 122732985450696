import { get } from "lodash";

let defaultState = {
	fetching: false,
	status: false,
	data: [],
	original: [],
	list: [],
	item: {},
	serverError: [],
	end: true,
	total: 0,
	action: '',
	limit: 7,
}
export default function listReducer(state = {
	blog: defaultState,
	caseStudies: defaultState,
}, action) {
	switch (action.type) {
		case "FETCH_LIST_PENDING": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...defaultState,
					item: {},
					action: action.meta.action,
					fetching: true
				}
			}
		}
		case "FETCH_LIST_REJECTED":
		case "APPEND_REJECTED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...defaultState
				}
			}
		}
		case "FETCH_LIST_FULFILLED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...defaultState,
					status: get(action.payload, 'status'),
					data: get(action.payload, "data", []),
					original: get(action.payload, "data", []),
					list: get(action.payload, "data", []),
					end: get(action.payload, "data", []).length < state[attribute].limit ? true : false,
				}
			}
		}

		case "APPEND_PENDING": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					action: action.meta.action,
					fetching: true,
					status: false
				}
			}
		}
		case "APPEND_FULFILLED": {
			const { attribute } = action.meta;
			let d = get(action.payload, "data", []);
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					list: [
						...state[attribute].list,
						...d
					],
					status: get(action.payload, 'status'),
					end: d.length < state[attribute].limit ? true : false,
				}
			}
		}
		default: {
			return state;
		}
	}
}