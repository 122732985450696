import contentsReducer from "./contentsReducer";
import listReducer from "./listReducer";
import submitReducer from "./submitReducer";

const reducer = {
    contents: contentsReducer,
    submit: submitReducer,
    list: listReducer
}

export default reducer;