import { get } from "lodash";

let defaultState = {
	fetching: false,
	status: false,
	errors: [],
	data: {}
}

export default function contentsReducer(state = {
	headerFooter: defaultState,
	page: defaultState,
}, action) {
	switch (action.type) {
		case "FETCH_CONTENT_PENDING": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: true,
				},
			}
		}
		case "FETCH_CONTENT_REJECTED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					status: get(action.payload, "response.data.status", 400),
				},
			}
		}
		case "FETCH_CONTENT_FULFILLED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					status: get(action.payload, 'status'),
					data: action.payload.data,
				},
			}
		}
		default: {
			return state;
		}
	}
}