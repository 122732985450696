import moment from 'moment';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import homebannerJson from '../assets/images/json/homebanner/homebanner.js';
import kieraJson from '../assets/images/json/kiera-knightley/kiera-knightley.js';
import astronautJson from '../assets/images/json/astronaut/astronaut.js';
import sarahJson from '../assets/images/json/sarah/sarah.js';
import stephenHawkingJson from '../assets/images/json/stephen_hawking/stephen_hawking.js';
import pageloadingJson from '../assets/images/json/pageloading/pageloading.json';
import astronautCryptoJson from '../assets/images/json/astronaut-crypto/astronaut-crypto.js';
import dogeDogJson from '../assets/images/json/doge_dog/doge_dog.js';
import contactJson from '../assets/images/json/contact/contact.js';

import {
	vwaveBgLightBlack,
	vwaveBgLightYellow,
	vwaveBgRed,
	lightYellow,
	ccbTopWaveLightBlack,
	ccbTopWaveLightYellow,
	ccbBottomWaveLightBlack,
	ccbBottomWaveLightYellow,
	realtedBottomWaveLB,
	realtedBottomWaveLY,
	realtedBottomWaveLR,
	realtedTopWaveLB,
	realtedTopWaveLY,
	realtedTopWaveLR
} from '../components/Images';

export const getAnimationJsons = (type) => {
	const aniArr = {
		homebanner: homebannerJson,
		'kiera-knightley': kieraJson,
		astronaut: astronautJson,
		astronaut_crypto: astronautCryptoJson,
		sarah: sarahJson,
		stephen_hawking: stephenHawkingJson,
		pageloading: pageloadingJson,
		doge_dog: dogeDogJson,
		contact: contactJson,
	};
	return get(aniArr, type);
};

export const getDynamicImg = (type, withoutWave = true) => {
	const aniArr = {
		bg_lightblack: vwaveBgLightBlack,
		bg_lightyellow: withoutWave ? vwaveBgLightYellow : lightYellow,
		bg_red: vwaveBgRed
	};

	return get(aniArr, type);
};

export const getCcbImg = (type, section = 'top') => {
	const ccbImg = {
		lightblack: section === 'top' ? ccbTopWaveLightBlack : ccbBottomWaveLightBlack,
		lightyellow: section === 'top' ? ccbTopWaveLightYellow : ccbBottomWaveLightYellow
	};

	return get(ccbImg, type);
};

export const getRelatedBlogImg = (type, section = 'top') => {
	const ccbImg = {
		lightblack: section === 'top' ? realtedTopWaveLB : realtedBottomWaveLB,
		lightyellow: section === 'top' ? realtedTopWaveLY : realtedBottomWaveLY,
		red: section === 'top' ? realtedTopWaveLR : realtedBottomWaveLR
	};

	return get(ccbImg, type);
};

export const getImg = (img) => {
	return <img src={img} alt='img'/>;
};

export const todayDateInUnix = () => {
	return Math.floor(moment.unix(moment()).valueOf() / 1000000);
};

export const formatDate = (d, format) => {
	if (!d) return '';
	if (!format) format = 'DD MMMM YYYY';
	return moment(d * 1000).format(format);
};

export const convertDateToUnix = (dateText) => {
	return Math.floor(moment.unix(moment(dateText)).valueOf() / 1000000);
};

export const composeValidators = (...validators) => (value) =>
	validators.reduce((error, validator) => error || validator(value), undefined);

export const smoothScroll = (classname, addHeaderHeight = false, analyzeScrolled = false, scrollTopOffset = 0) => {
	if (classname) {
		let item = $(`${classname}`);
		let sto = scrollTopOffset;
		if (item.length) {
			const headerHeight = $(`.header`).innerHeight();
			if (addHeaderHeight === true) {
				sto += headerHeight;
			}
			$('html, body').animate({
				scrollTop: item.offset().top - sto
			}, 500, function () {
				if (addHeaderHeight === true) {
					if (analyzeScrolled === true && headerHeight !== $(`.header`).innerHeight()) {
						smoothScroll(classname, addHeaderHeight, false, scrollTopOffset);
					}
				}
			});
		}
	}
};

export const showToast = (errors, status, multiple) => {
	if (multiple !== true) {
		multiple = false;
	}

	let className = '';
	if (status === 'success') {
		className = 'success';
	}

	if (Array.isArray(errors)) {
		errors = errorMessage(errors, multiple);
	}

	toast(errors, {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: false,
		className: className
	});
};

export const errorMessage = (errors, multiple) => {
	if (errors.length) {
		if (multiple === true) {
			let temp = [];
			errors.map((error, e) => {
				temp.push(error[e]);
				return true;
			});
			return temp.join('\n\n');
		}
		return errors[0];
	}
	return '';
};

export const buildFormData = (data) => {
	var bodyFormData = new FormData();

	if (Object.keys(data).length) {
		Object.keys(data).map((value) => {
			bodyFormData.append(value, get(data, value, ''));
			return true;
		});
	}
	return bodyFormData;
};

export function getContactFormErrors(sentError) {
	let errArr = [];
	if (sentError.length) {
		sentError.map(({ field, message }) => {
			errArr[field] = message;
			return true;
		});
	}
	return errArr;
}

export function matchHeight(element) {
	var col = document.querySelectorAll(element);
	if (col) {
		col = document.getElementsByClassName(element);
	}
	var group = [];
	for (let i = 0; i < col.length; i++) {
		col[i].style.height = 'auto';
		group.push(col[i].scrollHeight);
	}
	for (let j = 0; j < col.length; j++) {
		col[j].style.height = Math.max.apply(Math, group) + 'px';
	}
}

export const embedUrl = (link, autoplay, loop) => {
	if (typeof autoplay == 'undefined') autoplay = true;
	if (typeof loop == 'undefined') loop = false;

	var pattern0 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/(.+)\/(.+)/g;
	var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
	var pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
	var embedUrl = '';

	// Pattern0 for people with private link
	if (pattern0.test(link)) {
		embedUrl = link.replace(pattern0, 'https://player.vimeo.com/video/$1?controls=1&h=$2');
		if (autoplay) {
			embedUrl += '&autoplay=0';
			if (loop) embedUrl += '&loop=1';
		} else if (loop) embedUrl += '&loop=1';
	} else if (pattern1.test(link)) {
		// Pattern1 for normal
		embedUrl = link.replace(pattern1, 'https://player.vimeo.com/video/$1?controls=1');
		if (autoplay) {
			embedUrl += '&autoplay=0';
			if (loop) embedUrl += '&loop=1';
		} else if (loop) embedUrl += '&loop=1';
	} else if (pattern2.test(link)) {
		embedUrl = link.replace(pattern2, 'https://www.youtube.com/embed/$1?rel=0&amp;showinfo=0&controls=1');
		if (autoplay) embedUrl += '&autoplay=0';
		if (loop) {
			var id = this.getID(embedUrl);
			embedUrl += '&loop=1&playlist=' + id;
		}
	}

	return embedUrl;
};

export const objectFit = (wrapper, target) => {
	var wid = 1280,
		hei = 720,
		twrapper = $(wrapper),
		wrapperWid = twrapper.width() * 0.9,
		wrapperHei = twrapper.height() * 0.9,
		newWid = wrapperWid,
		newHei = Math.floor(newWid * hei / wid);

	if (newHei > wrapperHei) {
		newHei = wrapperHei;
		newWid = Math.floor(newHei * wid / hei);
	}

	$(target).css({
		width: newWid + 'px',
		height: newHei + 'px'
	});
};

export const storageGetItem = (name) => {
	return get(window.localStorage, name, '');
};
