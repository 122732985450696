import React from 'react';
import Lottie from "lottie-react";
import Spinner from '../Spinner';
import { getAnimationJsons } from '../Utils';
import style from './style.module.css';

const WhiteLoader = ({ minHeight = "120px", backgroundColor = "#121210", showLogoLoading = false }) => {
    return (
        <div className={`${style.whiteLoader}`} style={{ minHeight: minHeight, backgroundColor: backgroundColor }}>
            <div className={`${style.loading}`}>
                {showLogoLoading === true && <Lottie animationData={getAnimationJsons('pageloading')} />}
                {showLogoLoading === false && <Spinner />}
            </div>
        </div>
    )
}

export default WhiteLoader;