import React, { Suspense, lazy } from 'react';
import { Routes, BrowserRouter, Route, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import WhiteLoader from './components/Placeholders/WhiteLoader';
import ScrollToTop from './components/ScrollToTop';

import style from './assets/css/style.module.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/font.awesome.css'
import 'swiper/css/bundle';

const NoMatch = lazy(() => import('./pages/NoMatch'));
const Page = lazy(() => import('./pages/index'));
const Header = lazy(() => import('./pages/Layouts/Header'));
const Footer = lazy(() => import('./pages/Layouts/Footer'));

const SuspendPage = (element, fallback = <WhiteLoader minHeight="100vh" />) => {
  return (
    <Suspense fallback={fallback}>
      {element}
    </Suspense>
  )
}

const App = () => {
  return (
    <BrowserRouter basename={`${process.env.REACT_APP_URL_PREFIX}`}>
      <ScrollToTop />
      <ToastContainer />
      {SuspendPage(<Header />, <></>)}
      <div className={`${style.viewport}`}>
        <div className={`${style.content}`}>
          <Routes>
            <Route element={<Outlet />}>
              <Route path="/:url" element={SuspendPage(<Page />)} />
              <Route path="/case-studies/:url" element={SuspendPage(<Page />)} />
              <Route path="/" element={SuspendPage(<Page />)} />
              <Route path="*" element={SuspendPage(<NoMatch />)} />
            </Route>
          </Routes>
        </div>
      </div>
      {SuspendPage(<Footer />, <WhiteLoader minHeight="600px" />)}
    </BrowserRouter>
  );
}

export default App;
